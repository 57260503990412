<template>
  <!-- main-enter 主要页面内容 -->
  <div class="main">
    <div class="banner">
      <div class="banner-bg"></div>
      <div class="banner-wrapper">
        <div class="banner-title">灯塔智能测评系统</div>
        <div class="banner-tip">六大特点</div>
        <div class="text-block">
          <div class="banner-intro">
            <p>国内唯一拥有国家专利的专业定位产品</p>
            <p>7个专业定位报告</p>
            <p>专业定位准确率超80%</p>
            <p>92个大类专业，703个小类专业全匹配</p>
            <p>双一流大学专业全把握</p>
          </div>
        </div>
        <div
          class="banner-start lj-btn lj-btn-primary"
          @click="onTestEntryClick()"
        >
          进入智能专业匹配测试
        </div>
      </div>
    </div>
    <div class="main-title" id="products">产品介绍</div>
    <div class="products">
      <div class="product">
        <div class="left">
          <div class="img-wrapper" id="product-0"></div>
        </div>
        <div class="right">
          <div class="product-title">智能专业定位系统</div>
          <p>
            免费版包括 2 个免费的内在特质测评报告：<span class="product-tag"
              >职业倾向报告</span
            ><span class="product-tag">人格倾向报告</span>
          </p>
        </div>
      </div>
      <div class="product">
        <div class="left">
          <div class="product-title">智能专业定位系统</div>
          <p>
            包括特质测评报告<span class="product-tag"
              >最适合（15个）与最不适合（5个）的高校大类专业报告</span
            ><span class="product-tag">高校大类专业匹配全景报告</span
            ><span class="product-tag">最适合的高校大类专业详细报告</span
            ><span class="product-tag">最适合专业的小类排序报告</span>
          </p>
        </div>
        <div class="right">
          <div class="img-wrapper" id="product-2"></div>
        </div>
      </div>
      <div class="product">
        <div class="left">
          <div class="img-wrapper" id="product-3"></div>
        </div>
        <div class="right">
          <div class="product-title">智能专业定位系统</div>
          <p>
            另外我们还向您提供<span class="product-tag"
              >学业规划老师的面对面咨询指导</span
            >
          </p>
          <p>
            届时您将得到资深的学业规划专家对深度报告解读，并为您提供量身定做的生涯规划方案。
          </p>
          <button class="product-price">
            <a style="color: white;" @click="jumpPage('HomeIndexMainMembers')"
              >专家介绍</a
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data: function () {
    return {}
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({path: dest})
    },
    onTestEntryClick: function () {
      this.$emit('enterass','major')
    }
  },
  mounted: function () {},
  components: {}
}
</script>

<style lang="less">
</style>
